import React from "react";
import "../../styles/Page.scss";
import Layout from "../../components/Layout";
import Note from "../../components/Note";
import PageInfo from "../../components/PageInfo";

import parametres from "../../content/advanced/images/calcvariable-settings.png";
import selection from "../../content/advanced/images/calcvariable-select.png";

export default function FeatCalcVariables() {
  return (
    <Layout title="Calculated variables">
      <div className="page-body">
        <PageInfo AdmLo AdmGl />
        <div className="page-title">
          <h1>Les variables calculées</h1>
        </div>
        <p>
          Les variables calculées sont des{" "}
          <a href="/fr/advanced/variables">
            <b>variables</b>
          </a>{" "}
          particulières permettant de calculer un résultat à partir des données
          d'autres variables existantes dans le même formulaire.
        </p>
        <p>
          Exemple :{" "}
          <em>
            Votre formulaire inclue les variables "taille du patient" et "poids
            du patient". Il est alors possible d'inclure la variable "IMC" qui
            sera calculée automatiquement à partir des deux données citées
            précédemment.
          </em>
        </p>
        <section>
          <h2 id="creation-de-la-variable">Création de la variable</h2>
          <Note type="note">
            Vous devez au préalable avoir créé une ou plusieurs variable(s)
            simple(s) pour définir une variable calculée.
          </Note>
          <p>
            Commencez par créer une variable de type "<b>Calcul</b>" (voir{" "}
            <a href="/fr/advanced/variables">
              <b>variables (simples)</b>
            </a>
            ). Renseignez les champs de l'onglet . puis dirigez-vous vers
            l'onglet <b>Paramètres avancés</b>.
          </p>
          <p>
            Cliquez sur le sous-menu <b>Configuration de calcul</b> pour accéder
            à toutes les opérations qu'exécutera la variable calculée.
          </p>
          <img
            src={parametres}
            alt="Configuration d'une variable calculée"
            className="centered"
          />
        </section>
        <section>
          <h2 id="définir-l-operation-de-la-variable-calculée">
            Définir l'opération de la variable calculée
          </h2>
          <p>
            Une variable calculée doit être définie par une fonction :
            c'est-à-dire une opération mathématique qu'elle appliquera sur les
            données d'entrée sélectionnées. Ces données d'entrée peuvent être
            des variables simples mais aussi des constantes.
          </p>
          <div className="responsive-table">
            <table>
              <tr>
                <td>Type de fonction</td>
                <td>Usage</td>
              </tr>
              <tr>
                <td>Moyenne</td>
                <td>
                  Calcule la moyenne entre les variables sélectionnées (cliquez
                  sur "<b>Ajouter un élément</b>" pour inclure plus de
                  variables). Une option à cocher permet d'effectuer le calcul
                  même si tous les champs ne sont pas remplis par l'utilisateur.
                </td>
              </tr>
              <tr>
                <td>IMC</td>
                <td>
                  Calcule un IMC (Indice de Masse Corporelle) à partir des
                  variables "poids" et "taille" sélectionnées.
                </td>
              </tr>
              <tr>
                <td>Diff</td>
                <td>Calcule une différence entre deux valeurs d'entrée.</td>
              </tr>
              <tr>
                <td>DiffDate</td>
                <td>Calcule la différence entre deux données de type dates.</td>
              </tr>
              <tr>
                <td>Ratio</td>
                <td>
                  Calcule le ratio entre des données entrantes par rapport à un
                  dénominateur. Possibilité d'ajouter un décalage ("offset") au
                  résultat.
                </td>
              </tr>
              <tr>
                <td>Remise à l'échelle</td>
                <td>
                  Permet de renormaliser une variable en fonction d'une plage
                  d'entrée et d'une plage de sortie.
                </td>
              </tr>
              <tr>
                <td>Somme</td>
                <td>
                  Calcule la somme de plusieurs données d'entrée. Une option à
                  cocher permet d'effectuer le calcul même si tous les champs ne
                  sont pas remplis par l'utilisateur.
                </td>
              </tr>
            </table>
          </div>
          <p>
            Pour sélectionner les données d'entrée, il vous suffit de cliquer
            sur le champ "<em>Aucune variable sélectionnée</em>", une fenêtre
            s'ouvre listant toutes les variables de l'étude. Cliquez sur celle
            que vous souhaitez utiliser comme donnée d'entrée.
          </p>
          <img
            src={selection}
            alt="Sélectionner une donnée d'entrée"
            className="centered"
          />
          <Note type="tip">
            L'opération effectuée s'affiche sous forme d'équation en bas de la
            fenêtre afin de faciliter la compréhension du calcul.
          </Note>
        </section>
      </div>
    </Layout>
  );
}
